import React, { useState, useEffect, useRef } from 'react'
import logo from '../images/logo.svg'
import { Link, navigate } from 'gatsby'
import { useOnClickOutside } from '../hooks/useClickOutside'
import OptBtn from './button'
const menu = {
    es: [
        {
            id: 'productos',
            text: 'Productos',
            link: null,
            target: '',
            showsubmenu: false,
            submenu: [
                {
                    id: 'multiagente_es',
                    text: 'WhatsApp Multiagente',
                    link: '/es/productos/whatsapp-multiagente/',
                    target: ''
                },
                {
                    id: 'chatbot_es',
                    text: 'WhatsApp Chatbot',
                    link: '/es/productos/chatbot-whatsapp/',
                    target: ''
                },
                {
                    id: 'marketing_es',
                    text: 'WhatsApp Marketing',
                    link: '/es/productos/marketing-digital-whatsapp/',
                    target: ''
                },
                {
                    id: 'reports_es',
                    text: 'WhatsApp Reportes',
                    link: '/es/productos/reportes-whatsapp/',
                    target: ''
                },
                {
                    id: 'chatgpt_es',
                    text: 'ChatGPT para ventas',
                    link: '/es/productos/chatgpt-para-ventas/',
                    target: ''
                }
            ]
        },
        {
            id: 'canales',
            text: 'Canales',
            link: null,
            target: '',
            showsubmenu: false,
            submenu: [
                {
                    id: 'whatsapp',
                    text: 'WhatsApp',
                    link: '/es/canales/whatsapp/',
                    target: ''
                }
            ]
        },
        {
            id: 'pricing',
            text: 'Precios',
            link: '/es/precios/',
            target: ''
        },
        {
            id: 'soluciones',
            text: 'Soluciones',
            link: null,
            target: '',
            showsubmenu: false,
            submenu: [
                {
                    id: 'ventas',
                    text: 'Ventas online',
                    link: '/es/soluciones/ventas-online/',
                    target: ''
                },
                {
                    id: 'soporte',
                    text: 'Servicio al cliente',
                    link: '/es/soluciones/servicio-al-cliente/',
                    target: ''
                },
                {
                    id: 'ecommerce',
                    text: 'E-commerce',
                    link: '/es/soluciones/e-commerce/',
                    target: ''
                },
                {
                    id: 'inmobiliarias',
                    text: 'Inmobiliarias',
                    link: '/es/soluciones/inmobiliarias/',
                    target: ''
                },
                {
                    id: 'concesionarios',
                    text: 'Concesionarios',
                    link: '/es/soluciones/concesionarios/',
                    target: ''
                }
            ]
        },
        {
            id: 'integraciones',
            text: 'Integraciones',
            link: null,
            target: '',
            showsubmenu: false,
            submenu: [
                {
                    id: 'hubspot',
                    text: 'Hubspot',
                    link: '/es/integraciones/hubspot/',
                    target: ''
                },
                {
                    id: 'zendesk',
                    text: 'Zendesk',
                    link: '/es/integraciones/zendesk/',
                    target: ''
                },
                {
                    id: 'freshdesk',
                    text: 'Freshdesk',
                    link: '/es/integraciones/freshdesk/',
                    target: ''
                },
                {
                    id: 'zoho',
                    text: 'Zoho',
                    link: '/es/integraciones/zoho/',
                    target: ''
                },
                {
                    id: 'gvamax',
                    text: 'GVAMax',
                    link: '/es/integraciones/gvamax/',
                    target: ''
                },
                {
                    id: 'tecnom',
                    text: 'Tecnom',
                    link: '/es/integraciones/tecnom/',
                    target: ''
                },
                {
                    id: 'generic',
                    text: 'Genérica',
                    link: '/es/integraciones/generica/',
                    target: ''
                }
            ]
        },
        {
            id: 'recursos',
            text: 'Recursos',
            link: null,
            showsubmenu: false,
            submenu: [
                {
                    id: 'blog',
                    text: 'Blog',
                    link: 'https://optiwe.com/blog/es/',
                    target: '_blank'
                },
                {
                    id: 'ayuda',
                    text: 'Ayuda',
                    link: 'https://soporte.optiwe.com/',
                    target: '_blank'
                },
                {
                    id: 'webinars',
                    text: 'Webinars',
                    link: '/es/recursos/webinars/',
                    target: ''
                }
            ]
        }
    ],
    en: [
        {
            id: 'productos',
            text: 'Products',
            link: null,
            target: '',
            showsubmenu: false,
            submenu: [
                {
                    id: 'multiagente_en',
                    text: 'WhatsApp Shared Inbox',
                    link: '/products/shared-whatsapp-inbox/',
                    target: ''
                },
                {
                    id: 'chatbot_en',
                    text: 'WhatsApp Chatbots',
                    link: '/products/whatsapp-chatbot/',
                    target: ''
                },
                {
                    id: 'marketing_en',
                    text: 'WhatsApp Marketing',
                    link: '/products/whatsapp-marketing/',
                    target: ''
                },
                {
                    id: 'reports_en',
                    text: 'WhatsApp Reports',
                    link: '/products/whatsapp-reports/',
                    target: ''
                },
                {
                    id: 'chatgpt_es',
                    text: 'ChatGPT for Sales',
                    link: '/products/chatgpt-for-sales/',
                    target: ''
                }
            ]
        },
        {
            id: 'pricing',
            text: 'Pricing',
            link: '/pricing/',
            targe: ''
        }
    ]
}
const urls = {
    es: {
        id: 'indexEs',
        home: {
            id: 'home',
            text: 'home',
            url: '/es/'
        }
    },
    en: {
        id: 'indexEn',
        home: {
            id: 'home',
            text: 'home',
            url: '/'
        }
    }
}
function Nav({ lang, showButtons = false }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const ref = useRef()
    const menuRef = useRef()
    const [selectedMenu, setSelectedMenu] = useState(null)
    const [selectedUrls, setSelectedUrls] = useState(null)
    function changeLanguage(e) {
        if (e.target.value === 'es') {
            navigate('/es/?lang=es')
        }
        if (e.target.value === 'en') {
            navigate('/?lang=en')
        }
    }
    function toggleMenu() {
        setIsMenuOpen((val) => !val)
    }
    function handleSubmenu(item) {
        const menu = [...selectedMenu]
        menu.forEach((link) => {
            if (item.id === link.id) {
                link.showsubmenu = !link.showsubmenu
            } else if (item.submenu && item.id !== link.id) {
                // Si hay otros submenu se cierran
                link.showsubmenu = false
            }
        })
        setSelectedMenu(menu)
    }
    useOnClickOutside(ref, () => {
        setIsMenuOpen(false)
    })
    useOnClickOutside(ref, () => {
        const menu = [...selectedMenu]
        menu.forEach((link) => {
            if (link.submenu) {
                link.showsubmenu = false
            }
        })
        setSelectedMenu(menu)
    })
    useEffect(() => {
        setSelectedMenu(menu[lang])
        setSelectedUrls(urls[lang])
    }, [lang])

    if (!selectedMenu) return null
    return (
        <header className={`container ow-nav ${isMenuOpen && 'ow-nav--open'}`} ref={ref}>
            <Link
                to={selectedUrls.home.url}
                className="ow-nav__link-logo d-flex align-items-center"
            >
                <img src={logo} alt="Optiwe Logo" className="ow-nav__logo" />
                <p className="ow-nav__title m-0">optiwe</p>
            </Link>
            <div className="ow-nav__lang_selector">
                <select
                    defaultValue={lang}
                    onBlur={changeLanguage}
                    onChange={changeLanguage}
                    className="ow-nav__lang-selector"
                >
                    <option value="es">ES</option>
                    <option value="en">EN</option>
                </select>
            </div>
            <button className="ow-nav__mobile-button" onClick={toggleMenu}>
                <span className="ow-nav__mobile-button-line"></span>
                <span className="ow-nav__mobile-button-line"></span>
                <span className="ow-nav__mobile-button-line"></span>
            </button>
            <nav className="ow-nav__list-wrapper ml-auto">
                <ul className="ow-nav__list" ref={menuRef}>
                    {selectedMenu &&
                        selectedMenu.map((item) => (
                            <li id={item.id} key={item.id} className="ow-nav__item d-lg-block">
                                {item.link
                                    ? (
                                        item.link.charAt(0) === '#'
                                            ? (
                                                <a href={item.link} className="ow-nav__link">
                                                    {item.text}
                                                </a>
                                            )
                                            : (
                                                <>
                                                    <Link
                                                        to={item.link}
                                                        target={item.target}
                                                        activeClassName="ow-nav__link--active"
                                                        className="ow-nav__link"
                                                    >
                                                        {item.text}
                                                    </Link>
                                                </>
                                            )
                                    )
                                    : (
                                        <>
                                            <span
                                                className={`ow-nav__sublink-text ${item.showsubmenu
                                                    ? 'ow-nav__sublink-text--open'
                                                    : null
                                                    }`}
                                                role="button"
                                                tabIndex={0}
                                                onKeyDown={() => handleSubmenu(item)}
                                                onClick={() => handleSubmenu(item)}
                                            >
                                                {item.text}
                                            </span>
                                            {item.submenu && (
                                                <ul
                                                    className={`ow-nav__sub-list ${item.showsubmenu && 'ow-nav__sub-list--show'
                                                        }`}
                                                >
                                                    {item.submenu.map((subitem) => (
                                                        <li
                                                            key={subitem.id}
                                                            className="ow-nav__sub-list-item"
                                                        >
                                                            <Link
                                                                to={subitem.link}
                                                                targe={item.target}
                                                                className="ow-nav__sub-list-link"
                                                            >
                                                                {subitem.text}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </>
                                    )}
                            </li>
                        ))}
                    {showButtons && lang === 'es' && (
                        <li id="test" key="test" className="ow-nav__item">
                            <OptBtn isLink rounded small href="/es/crear-cuenta/">
                                Probar gratis
                            </OptBtn>
                        </li>
                    )}
                    {showButtons && lang === 'en' && (
                        <li id="test" key="test" className="ow-nav__item">
                            <OptBtn isLink rounded small href="/sign-up/">
                                Start free
                            </OptBtn>
                        </li>
                    )}
                    <li id="language" key="language" className="ow-nav__item">
                        <select
                            defaultValue={lang}
                            onBlur={changeLanguage}
                            onChange={changeLanguage}
                            className="ow-nav__lang-selector"
                        >
                            <option value="es">ES</option>
                            <option value="en">EN</option>
                        </select>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Nav
