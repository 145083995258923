import React from 'react'

function OptBtn ({ children, href, isLink, clickCb, small, rounded, outlined, style }) {
    function getClasses () {
        let classNames = 'ow-button'
        if (rounded) classNames += '  ow-button--rounded'
        if (small) classNames += '  ow-button--small'
        if (outlined) classNames += '  ow-button--outlined'
        return classNames
    }
    if (!isLink) {
        return (
            <button type="button" onClick={clickCb} style={style} className={getClasses()}>
                {children}
            </button>
        )
    }
    return (
        <a href={href} style={style} className={getClasses()}>
            {children}
        </a>
    )
}
export default OptBtn
