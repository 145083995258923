
import React from 'react'
import Layout from '../../components/layout'
import Nav from '../../components/nav'
import SEO from '../../components/seo'

import { ErrorBoundaryWithLogger } from '../../frontend-lib/Log'

const Privacy = () => {
    const lang = "en"
    return (
        <ErrorBoundaryWithLogger serverUrl={process.env.GATSBY_SERVER_URL} logName="website">
            <Layout lang={lang}>
                <SEO
                    title={"Optiwe - Privacy Policy"}
                    description={"Privacy policy of the service"}
                    lang={"en"}
                    locales={[
                        { hreflang: 'en', href: 'https://optiwe.com/legal/privacy' },
                        { hreflang: 'x-default', href: 'https://optiwe.com/legal/privacy' }
                    ]}
                />

                <main className="container-fluid ow-banner">
                    {/*
                    ^*********
                    * HEADER *
                    **********
                    */}
                    <Nav
                        lang={lang}
                        showButtons
                    />

                    <div className="container ow-banner__content">
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12 ow-banner__home-content">
                                <h1>Optiwe, Inc. Privacy Policy</h1>
                                <h2>Effective May 1st, 2020</h2>
                                <p>
                                    At Optiwe, Inc. (“Optiwe”) we take privacy and your personal data very seriously. Please read this Privacy Policy (the “Policy”) carefully.
                                    This Policy describes how we collect, use, share and process personal information and your rights
                                    and choices regarding such information, when you use our websites, data analytics software and
                                    other services (collectively, the “Services”). By using, participating in, or accessing our Services, you
                                    acknowledge that you accept and consent to the practices described in this Policy.
                                </p>
                                <h3>1. Why and what Information is collected as you use our Services</h3>
                                <p>
                                    We collect and use the following information to provide and improve our Services and as otherwise described in this Privacy Policy. Optiwe may also use the information to measure the performance
                                    and develop new services.
                                </p>
                                <p>
                                    Account Information:
                                </p>
                                <p>
                                    We collect information associated with your account, like your password, name, company, email
                                    address, location, phone number, account and website activity, IP addresses, cookies identifiers, login
                                    times and other information you provide.
                                </p>
                                <p>
                                    Services Information:
                                </p>
                                <p>
                                    Our data analytics services enable customers and their personnel to record, analyze and share the
                                    contents of telephone calls, video calls, online demonstrations, webinars, communications, and
                                    associated data and documentation. These recordings and analysis thereof may contain personal
                                    information, such as names, titles, and contact information.
                                    Optiwe may collect, reproduce, process, analyze, summarize and disclose these files, recordings and
                                    any results of our Services with such customers and their relevant personnel and other team
                                    members, and customers may share this information with their personnel and others.    
                                </p>
                                <h3>2. How Optiwe uses the collected Information.</h3>
                                <p>
                                    We collect information related to how you use the Services in order to help us provide you with
                                    additional features, monitor and improve the Services.
                                    We also collect information from and about the devices you use to access the Services, such as IP
                                    addresses, the type of browser and device you use and identifiers associated with your devices.
                                    We will use machine learning techniques on response data, metadata and cookie data, in order to
                                    provide users with useful and relevant insights from the data they have collected using our Services,
                                    to build features, improve our services and to develop aggregated data products.
                                    We also use technologies like cookies to provide, improve, protect and promote our Services. For
                                    example, cookies help us with things like remembering your username for your next visit,
                                    understanding how you are interacting with our Services, and improving them based on that
                                    information.
                                    You can always opt not to disclose information to us, but keep in mind that some information may be
                                    needed to create an account or take advantage of some of our features.
                                </p>
                                <h3>3. With Whom Optiwe Share Your Information?</h3>
                                <p>
                                    We do not share your information or data with third parties outside Optiwe except in the following
                                    limited circumstances:
                                    Your account information and data may be shared with the primary administrator(s) and may also be
                                    visible to other members in your organizacion or your team or with whom you collaborate. Your
                                    administrator(s) will be able to view your account data, change your passwords, suspend, transfer or
                                    terminate your account or restrict your settings. Please refer to your organization’s internal policies if
                                    you have questions about this.
                                    You or your team administrators can also give or direct us to give third parties access to your
                                    information and account – for example, via third party CRM platforms.
                                    To help us provide certain aspects of our services we use affiliates and trusted key partners. We
                                    enter into confidentiality and data processing terms with partners to ensure they comply with high
                                    levels of confidentiality and best practice in privacy and security standards and we regularly review
                                    these standards and practices.
                                    On your instructions, we share your information or data if you choose to use an integration in
                                    conjunction with Optiwe, Inc. services, to the extent necessary to facilitate that use.
                                </p>
                                <p>We also may have to share information or data in order to:</p>
                                <p>i. meet any applicable law, regulation, legal process or enforceable governmental request.</p>
                                <p>ii. enforce applicable policies, including investigation of potential violations.</p>
                                <p>iii. detect, prevent, or otherwise address fraud, security or technical issues.</p>
                                <p>
                                    iv. protect against harm to the rights, property or safety of our users, the public or to Optiwe and/or
                                    as required or permitted by law.
                                </p>
                                <p>
                                    v. facilitate a sale, merger or change in control of all or any part of our company or business or in
                                    preparation for any of these events.
                                </p>
                                <p>        
                                    We also may anonymize or aggregate your personal information so that you are not individually
                                    identified (“Anonymized Data”), and we may use such Anonymized Data to improve our services.
                                    Optiwe may provide such Anonymized Data to our partners, who may use that information in
                                    anonymized form to understand how often and in what ways people use our Services so that they,
                                    too, can provide you with an optimal online experience and/or better services.
                                    We will never disclose aggregate usage information to a partner without your consent or in a
                                    manner that would identify you personally as an individual except as required to perform the
                                    Services or as otherwise set forth in this Policy.
                                </p>
                                <h3>4. How We Store & Protect Your Information</h3>
                                <p>
                                    We implement a variety of security measures to maintain the safety of your personal information
                                    when you use our ‘Services’. We offer the use of a secure server. All supplied sensitive/credit
                                    information is transmitted via Transport Layer Security (TLS) technology and then stored into our
                                    database to be only accessed by those authorized with special access rights to our systems, and are
                                    required to keep the information confidential. We use a combination of Amazon AWS and Google
                                    Cloud infrastructure to provide our services and store the data. If you have any questions about the
                                    security of your personal information, you can contact us at info@optiwe.com.
                                </p>
                                <p>
                                    - Personal data we collect is stored on infrastructure provided by Amazon and Google within the
                                    United States.
                                </p>
                                <p>
                                    - Amazon and Google implement and maintains industry-leading security standards.
                                </p>
                                <p>
                                    - Amazon and Google implement and maintains industry-leading security standards.
                                </p>
                                <p>
                                    - Amazon and Google implement and maintains industry-leading security standards.
                                </p>
                                <p>
                                    - You can read more about Amazon AWS and Google Cloud Services and its security features on
                                    their webpage.
                                </p>
                                <p>
                                    - However, no means of transmission over the internet or electronic storage is 100% secure, and
                                    while we endeavor to use commercially reasonable means to protect personal information, we
                                    cannot guarantee its absolute security.
                                </p>
                                <p>
                                    - If you access our Services via a third party CRM platform or Cloud calendar (Slack, SalesForce), you
                                    should also protect your account with secure account credentials and prevent unauthorized access to
                                    your account and personal information.
                                </p>
                                <h3>5. Retention</h3>
                                <p>
                                    Optiwe will retain information for as long as we need it to provide you the Services or to comply with
                                    applicable law. If you discontinue the Service, this information can be deleted upon request or based
                                    on the terms in our commercial agreements.
                                    But please note:
                                </p>
                                <p>
                                    (1) there might be some latency in deleting this information from our servers and backup storage;
                                </p>
                                <p>
                                    (2) we will not delete Anonymized Data and may continue to use it as describe in this Privacy Policy;
                                    and
                                </p>
                                <p>
                                    (3) we may retain information if necessary to comply with our legal obligations, resolve disputes,
                                    manage security risks, or enforce our agreements.
                                </p>
                                <h3>6. Changes</h3>
                                <p>
                                    If we are involved in a reorganization, merger, acquisition or sale of our assets, bankruptcy or similar
                                    event, your information may be transferred as part of the evaluation of and consummation of that
                                    deal.
                                    We will notify you (for example, via a message to the email address associated with your account) of
                                    any such deal where we are not the surviving entity of such transaction, and outline your choices.
                                    We may revise this Policy from time to time, and will post the most current version on our website.
                                    If a revision meaningfully reduces your rights, we will notify you. By using the Services after any
                                    changes, you acknowledge your agreement to such changes and that use of information collection is
                                    subject to the Policy in effect when such information is used.
                                </p>
                                <h3>7. Access and Contact</h3>
                                <p>
                                    Through your account settings, you may access, and in some cases, edit or delete certain information
                                    you provide to Optiwe.
                                    Please be aware that the information you may view, update or delete may change as the Services
                                    change.
                                    If your personal information has been provided to us by one of our customers, please contact that
                                    customer to request any access to, correction of, or removal of your information.
                                    If there are any questions regarding this privacy policy, the practices of this website or your dealings
                                    with this website, you may contact us at info@optiwe.com
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        </ErrorBoundaryWithLogger>
    )
}
export default Privacy
